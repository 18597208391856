import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import HomePage from './pages/HomePage';
import UserProfilePage from './pages/UserProfilePage';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Login from './components/Authentication/Login';
import Register from './components/Authentication/Register';
import NewHomePage from './pages/NewHomePage';
import Config from './pages/ConfigPage'
import TornadoPage from './pages/TornadoPage';
import HealthCheck from './pages/HealthCheckPage';


function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen bg-white">
          <Header />
          <main className="flex-grow container mx-auto px-4 py-8">
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/dashboard" component={NewHomePage} />
              <Route path="/profile" component={UserProfilePage} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/config" component={Config} />
              <Route path="/tornado" component={TornadoPage} />
              <Route path="/health" component={HealthCheck}/>
              <Redirect to="/" />
            </Switch>
          </main>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;