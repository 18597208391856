import React, { useState, useEffect, useRef, useCallback } from 'react';
import AccountList from '../components/Dashboard/AccountList';
import AssetList from '../components/Dashboard/AssetList';
import ControlPanel from '../components/Dashboard/ControlPanel';
import StatisticsTable from '../components/Dashboard/StatisticsTable';
import TradingViewWidget from '../components/Dashboard/TradingViewWidget';
import { 
  fetchAccounts,
  fetchAssets,
  fetchStatistics,
  changeActiveSource,
  toggleSide,
  fetchDeepAssets,
  toggleActive,
  toggleOffensive,
  toggleMaster,
  toggleFestival,
  switchMaster,
  fetchMasterStatus,
  updateAssetConfig,
  fetchUserProfile,
  toggleSpinDown,
  setTimeframeSide,
  setJ2F,
  getAuthToken
} from '../services/api';
import { reversePatchCrypto, reversePatchSec, timeframes } from '../utils/helpers';
import ConfigPanel from '../components/Dashboard/ConfigPanel';
import ToggleSwitch from '../components/common/ToggleSwitch';
import OptionsForm from '../components/Dashboard/OptionsForm';

function NewHomePage() {
  const timeframes_ws = useRef();

  const [timeframesInfo, setTimeframesInfo] = useState(null);
  const [timeframesWsConnected, setTimeframesWsConnected] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [assets, setAssets] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [tvSymbol, setTVSymbol] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedStrategy, setSelectedStrategy] = useState('gyrox-v2');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [unifiedData, setUnifiedData] = useState([]);
  
  const [userProfile, setUserProfile] = useState(null);

  const [isFetching, setIsFetching] = useState(false); 
  const [skipFetchFlag, setSkipFetchFlag] = useState(false);
  const [masterSwitch, setMasterSwitch] = useState(null);
  const [isUnified, setIsUnified] = useState(true);
  const [wsMounted, setwsMounted] = useState(false);

  useEffect(() => {
    const loadAccounts = async () => {
      const fetchedAccounts = await fetchAccounts();
      setAccounts(fetchedAccounts);
    };
    const loadUserProfile = async () => {
      const profile = await fetchUserProfile();
      setUserProfile(profile);
    };
    loadAccounts();
    loadUserProfile();

    return () => {
      timeframes_ws.current.close();
    };
  }, []);

  useEffect(
    () => {
      if (!isUnified && timeframesWsConnected) {
        timeframes_ws.current.close();
        setTimeframesInfo(null);
        setTimeframesWsConnected(false);
        connectTimeframesWs();
      }
      if (!isUnified && !timeframesWsConnected)  {
        connectTimeframesWs();
      }
    }, [selectedAsset]
  )

  const connectTimeframesWs = () => {
    timeframes_ws.current = new WebSocket(`${process.env.REACT_APP_API_WS_BASE_URL}/fetch-timeframes-socket`)
    console.log("Connection estabilished");
    const payload = {};
    payload.symbol = selectedAsset.symbol;
    payload.broker = selectedAsset.broker;
    payload.sectype = selectedAsset.sectype;
    payload.account = selectedAsset.broker === 'okx' ? userProfile.name : selectedAsset.account;
    payload.strategy = selectedStrategy;
    payload.Authentication = getAuthToken();
    timeframes_ws.current.onopen = () => {timeframes_ws.current.send(JSON.stringify(payload)); setTimeframesWsConnected(true);};
    gettingTimeframesData();
  }

  const gettingTimeframesData = useCallback(() => {
    if (!timeframes_ws.current) return;
    timeframes_ws.current.onmessage = body => {
      console.log(body.data);
      const data = body.data;
      if (data !== 'ping') {
        setTimeframesInfo(JSON.parse(data));
      }
    }
  }, [])


  useEffect(() => {
    const loadAssets = async () => {
      if (selectedAccount) {
        const fetchedAssets = await fetchAssets(
          selectedAccount.account,
          selectedAccount.broker
        );
        if (Array.isArray(fetchedAssets)) {
          setAssets(fetchedAssets);
        } else {
          setAssets([fetchedAssets])
        }
      }
    };
    setwsMounted(false);
    loadAssets();
  }, [selectedAccount]);

  useEffect(() => {
    const loadData = async () => {
      if (selectedAsset) {
        switch (selectedAsset.broker) {
          case "okx":
              setTVSymbol(`OKX:${reversePatchCrypto(selectedAsset.symbol)}`);
              break;
          case "ig":
          case "ib":
              const reversedPatch = reversePatchSec(
                  selectedAsset.symbol,
                  selectedAsset.sectype
              );
              setTVSymbol(`${(reversedPatch[1] === undefined) ?
                  (selectedAsset.exchange) :
                  (reversedPatch[1])}:${reversedPatch[0]}`);
              break;
          default:
              break;
        }
        const fetchedStatistics = await fetchStatistics(
          selectedAccount.broker,
          selectedAccount.account,
          selectedAsset.symbol,
          selectedAsset.sectype
        );
        setStatistics(fetchedStatistics);
      }
    };
    const refreshMasterStatus = async () => {
      try {
        const newStatus = await fetchMasterStatus(selectedAsset.broker);
        setMasterSwitch(newStatus.status);
        setwsMounted(true);
      } catch (error) {
        console.error('Ошибка при обновлении состояния брокера:', error);
      }
    };
    setwsMounted(false);
    if (!skipFetchFlag) {
      loadData();
      if (selectedAsset) {
        refreshMasterStatus();
      }
    } else {
      setwsMounted(true);
      setSkipFetchFlag(false);
    }
  }, [selectedAsset]);

  const handleDeepFetch = async () => {
    if (selectedAccount) {
      setIsFetching(true); 
      try {
        const fetchedDeepAssets = await fetchDeepAssets(
          selectedAccount.broker,
          selectedAccount.account
        );
        setAssets(fetchedDeepAssets); 
      } catch (error) {
        console.error('Error during deep fetch:', error);
      } finally {
        setIsFetching(false); 
      }
    }
  };

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
    setSelectedAsset(null);
  };

  const handleAssetSelect = (asset) => {
    setTVSymbol(null);
    setStatistics(null);
    setwsMounted(false);
    setSelectedAsset(asset);
  };

  const handleUpdateConfig = async (symbol, broker, basePosition, step, timeframe, account, sectype) => {
    await updateAssetConfig(symbol, broker, basePosition, step, timeframe, selectedStrategy, broker === 'okx' ? userProfile.name : account, sectype);
    // const localAsset = structuredClone(selectedAsset);
    // localAsset.base_quantity = basePosition;
    // localAsset.step_quantity = step;
    // selectedStrategy === 'gyrox-v1' ?
    //   localAsset.active_timeframe_v1 = timeframe :
    //   localAsset.active_timeframe_v2 = timeframe;
    // setSelectedAsset(localAsset);
    // setwsMounted(false);
  };

  const handleChangeActivePos = async (sourceName, asset, timeframe) => {
    if (!isUnified) {
      await changeActiveSource(sourceName, selectedAsset.symbol, selectedAsset.broker, timeframe, selectedStrategy, selectedAsset.broker === 'okx' ? userProfile.name : selectedAsset.account, selectedAsset.sectype);
    } else {
      await changeActiveSource(sourceName, asset.symbol, asset.broker, timeframe, selectedStrategy, asset.broker === 'okx' ? userProfile.name : asset.account, asset.sectype);
    }
  };

  const handleToggleSide = async (sourceName, asset, timeframe) => {
    try {
      if (!isUnified) {
        await toggleSide(sourceName, selectedAsset.symbol, selectedAsset.broker, timeframe, selectedAsset.account, selectedAsset.sectype);
      } else {
        await toggleSide(sourceName, asset.symbol, asset.broker, timeframe, asset.account, asset.sectype);
      }
    } catch (error) {
      console.error("Error toggling side:", error);
    }
  };

  const handleSetTimeframeSideClick = async (side, timeframe) => {
    try {
      await setTimeframeSide(
        side,
        selectedAsset.symbol,
        selectedAsset.broker,
        selectedStrategy,
        timeframe,
        selectedAsset.account,
        selectedAsset.sectype
      );
    } catch (error) {
      console.error("Error toggling side:", error);
    }
  };

  const handleSetJ2F = async (timeframe, isJ2F) => {
    try {
      const result = await setJ2F(
        isJ2F,
        selectedAsset.symbol,
        selectedAsset.broker,
        timeframe,
        selectedAsset.broker === 'okx' ? userProfile.name : selectedAsset.account,
        selectedAsset.sectype
      );
      // const localAsset = structuredClone(selectedAsset);
      // result.kind === 'j2f' ?
      //   localAsset.isJ2F = result.new_status :
      //   localAsset.is02F = result.new_status;
      // setSkipFetchFlag(true);
      // setSelectedAsset(localAsset);
      // setwsMounted(false);
    } catch (error) {
      console.error("Error setting J2F:", error);
    }
  };

  const handleToggleSpinDown = async () => {
    try {
      const response = await toggleSpinDown(selectedAsset.symbol, selectedAsset.broker, selectedAsset.account, selectedAsset.sectype);
      const assetClone = structuredClone(selectedAsset);
      assetClone.isSpinDown = response;
      setSkipFetchFlag(true);
      setSelectedAsset(assetClone);
    } catch (error) {
      console.error("Error toggling spin down:", error);
    }
  };


  const handleToggleActiveAssetClick = async () => {
    try {
      const newStatus = await toggleActive(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (newStatus !== null) {
        await handleDeepFetch();
        setSkipFetchFlag(true);
        handleAssetSelect(null);
        
      
      } else {
        alert('Не удалось переключить active режим. Проверьте логи.');
      }
    } catch (error) {
      console.error('Ошибка при переключении активности актива:', error);
    }
  };


  const handleToggleOffensiveAssetClick = async () => {
    try {
      const response = await toggleOffensive(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (response === null) {
        alert('Не удалось переключить offensive режим. Проверьте логи.');
      } else {
        const assetClone = structuredClone(selectedAsset);
        assetClone.isOffensive = response;
        setSkipFetchFlag(true);
        setSelectedAsset(assetClone);
      }
    } catch (error) {
      console.error('Ошибка при переключении offensive режима:', error);
    }
  };

  const handleToggleAlgoAssetClick = async () => {
    try {
      const response = await toggleMaster(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (response === null) {
        alert('Не удалось переключить fade режим. Проверьте логи.');
      } else {
        const assetClone = structuredClone(selectedAsset);
        assetClone.isAlgoActive = response;
        setSkipFetchFlag(true);
        setSelectedAsset(assetClone);
      }
    } catch (error) {
      console.error('Ошибка при переключении алгоритма:', error);
    }
  };

  const handleToggleFestivalAssetClick = async () => {
    try {
      const response = await toggleFestival(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (response === null) {
        alert('Не удалось переключить festival режим. Проверьте логи.');
      } else {
        const assetClone = structuredClone(selectedAsset);
        assetClone.isFestival = response;
        setSkipFetchFlag(true);
        setSelectedAsset(assetClone);
      }
    } catch (error) {
      console.error('Ошибка при переключении фестиваля:', error);
    }
  };

  const handleMasterSwitchClick = async (broker) => {
    try {
      const newStatus = await switchMaster(broker);
      setMasterSwitch(newStatus);
    } catch (error) {
      console.error('Ошибка при переключении мастера:', error);
    }
  };

  useEffect(() => {
    const loadUnifiedData = async () => {
      const result = [];
      for (let i = 0; i < assets.length; i++) {
        const entry = {};
        entry.asset = assets[i];
        result.push(entry);
      }
      setUnifiedData(result);
      setwsMounted(true);
    }
    if (isUnified) {
      loadUnifiedData();
    } else {
      setwsMounted(true);
    }
  }, [isUnified, assets, selectedStrategy]);
  

  const handleUnifiedSwitchClick = (isUnifiedSelected) => {
    setIsUnified(isUnifiedSelected);
  };

  // const handleStrategySwitchClick = (strategy) => {
  //   setwsMounted(false);
  //   switch (strategy) {
  //     case false:
  //       setSelectedStrategy('gyrox-v1');
  //       break;
  //     case true:
  //       setSelectedStrategy('gyrox-v2');
  //       break;
  //     default:
  //       break;
  //   }
  // };


  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold">Dashboard</h1>
        <AccountList accounts={accounts} onSelect={handleAccountSelect} />
      {selectedAccount && (
        <div className='flex flex-col gap-1 sm:flex-row sm:gap-0'>
          <ToggleSwitch
            defaultOpt="left"
            onToggle={handleUnifiedSwitchClick}
            OptionTextOFF="UNIFIED"
            OptionTextON="SOLO"
          />
          {/* <ToggleSwitch
            defaultOpt="left"
            onToggle={handleStrategySwitchClick}
            OptionTextOFF="GYROX ver.2"
            OptionTextON="GYROX ver.1"
          /> */}
          <div className='flex items-center justify-between'>
          <button
            className={`p-3 bg-white rounded-lg shadow-lg ${
              isFetching ? 'opacity-30 cursor-not-allowed' : ''
            }`}
            onClick={handleDeepFetch}
            disabled={isFetching}
          >
            <div className="flex justify-center items-center">
              <img
                src="https://www.svgrepo.com/show/167751/refresh.svg"
                alt={isFetching ? "Refreshing" : "Refresh"}
                className={`h-6 w-6 ${isFetching ? "animate-spin" : ""}`}
                style={{ "animation-direction": "reverse" }}
              />
            </div>
          </button>
        </div>
        </div>
      )}
      {isUnified && wsMounted && (
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
          {unifiedData
          .filter((entry) => {return entry.asset.isAssetActive})
          .map(entry => (
            <ControlPanel
              strategy={selectedStrategy}
              gridLocation=''
              timeframe={selectedStrategy === 'gyrox-v1' ? entry.asset.active_timeframe_v1 : entry.asset.active_timeframe_v2}
              asset={entry.asset}
              handleChangeActivePos={handleChangeActivePos}
              handleToggleSide={handleToggleSide}
            />
          ))}
        </div>
      )}
      {!isUnified && selectedAccount && (
        <AssetList
          assets={assets}
          onSelect={handleAssetSelect}
          detailed={false}
          handleDeepFetch={handleDeepFetch}
          isFetching={isFetching}
        />
      )}
      {!isUnified && selectedAsset && tvSymbol && (
        <TradingViewWidget tvSymbol={tvSymbol}/>
      )}
        {!isUnified && selectedAsset && masterSwitch !== null && wsMounted && timeframesInfo && (
          <div className="grid grid-cols-1 grid-rows-5 sm:grid-cols-3 sm:grid-rows-2">
            {(timeframes.indexOf(timeframesInfo.active_timeframe) - 1) > 0 && (<ControlPanel
              gridLocation={'sm:col-start-1 sm:col-end-1 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-1 row-end-1'}
              strategy={selectedStrategy}
              timeframe={timeframes[timeframes.indexOf(timeframesInfo.active_timeframe) - 1]}
              asset={selectedAsset}
              handleChangeActivePos={handleChangeActivePos}
              handleToggleSide={handleToggleSide}
            />)}
            {(timeframes.indexOf(timeframesInfo.active_timeframe) - 1) <= 0 && (
              <div className='sm:col-start-1 sm:col-end-1 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-1 row-end-1'></div>
            )}
            <ControlPanel
              gridLocation={'sm:col-start-2 sm:col-end-2 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-2 row-end-2'}
              strategy={selectedStrategy}
              timeframe={timeframesInfo.active_timeframe}
              asset={selectedAsset}
              handleChangeActivePos={handleChangeActivePos}
              handleToggleSide={handleToggleSide}
            />
            {(timeframes.indexOf(timeframesInfo.active_timeframe) + 1) < timeframes.length && (<ControlPanel
              gridLocation={'sm:col-start-3 sm:col-end-3 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-3 row-end-3'}
              strategy={selectedStrategy}
              timeframe={timeframes[timeframes.indexOf(timeframesInfo.active_timeframe) + 1]}
              asset={selectedAsset}
              handleChangeActivePos={handleChangeActivePos}
              handleToggleSide={handleToggleSide}
            />)}
            {(timeframes.indexOf(timeframesInfo.active_timeframe) + 1) >= timeframes.length && (
              <div className='sm:col-start-3 sm:col-end-3 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-3 row-end-3'></div>
            )}
            <ConfigPanel
              selectedAsset={selectedAsset}
              masterSwitch={masterSwitch}
              gridLocation={'sm:col-start-1 sm:col-end-1 sm:row-start-2 sm:row-end-2 col-start-1 col-end-1 row-start-4 row-end-4'}
              handleToggleAlgoAssetClick={handleToggleAlgoAssetClick}
              handleToggleSpinDownClick={handleToggleSpinDown}
              handleToggleActiveAssetClick={handleToggleActiveAssetClick}
              handleSetTimeframeSideClick={handleSetTimeframeSideClick}
              handleMasterSwitchClick={handleMasterSwitchClick}
              handleSetJ2F={handleSetJ2F}
              timeframesInfo={timeframesInfo}
            />
            <OptionsForm
              asset={selectedAsset}
              timeframesInfo={timeframesInfo}
              timeframeOptions={timeframes}
              handleUpdateConfig={handleUpdateConfig}
            />
          </div>
        )}
          <div>
            {!isUnified && selectedAsset && tvSymbol && (
              <StatisticsTable data={statistics} />
            )}
          </div>
    </div>
  );
}

export default NewHomePage;