import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { logout } from '../../services/auth';


function Header() {
  const { user, setUser } = useContext(AuthContext);
  const [ isLoggedIn, setIsLoggedIn ] = useState(!!user);
  const history = useHistory();

  useEffect(() => {
    setIsLoggedIn(!!user);
    if (user) {
      history.push('/dashboard');
    }
  }, [user, history]);

  const handleLogout = () => {
    logout();
    setUser(null);
    history.push('/login');
  };

  return (
    <header className="bg-white border-b-2 border-accent">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="text-black bodoni-moda pr-4">
          <h1 className='text-md sm:text-2xl font-bold'>MOZART TRADER</h1>
          <p className='text-xs sm:text-sm'>trade your symphony</p>
        </Link>
        <nav>
          <ul className="flex space-x-4">
            {user ? (
              <>
                <li><Link to="/dashboard" className="text-gray-700 hover:text-accent">Dashboard</Link></li>
                <li><Link to="/profile" className="text-gray-700 hover:text-accent">Profile</Link></li>
                <li><Link to = "/config" className="text-gray-700 hover:text-accent">Config</Link></li>
                <li><Link to = "/tornado" className="text-gray-700 hover:text-accent">Tornado</Link></li>
                <li><Link to = "/health" className="text-gray-700 hover:text-accent">Healthchecks</Link></li>
                <li><button onClick={handleLogout} className="text-gray-700 hover:text-accent">Logout</button></li>
              </>
            ) : (
              <>
                <li><Link to = "/tornado" className="text-gray-700 hover:text-accent">Tornado</Link></li>
                <li><Link to="/login" className="text-gray-700 hover:text-accent">Login</Link></li>
                <li><Link to="/register" className="text-gray-700 hover:text-accent">Register</Link></li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;