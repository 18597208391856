import React, { useState, useEffect } from "react";
import { sendHealthChecks } from "../services/api";

const HealthCheck = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  const handleFetchHealth = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const result = await sendHealthChecks();
      setServices(result.statuses);
      if (!result.success) {
        setError(result.message);
      }
    } catch (err) {
      setError(`Failed to load: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchHealth();
  }, []);

  return (
    <div className="p-8" style={{ backgroundColor: 'rgba(250, 249, 246, 0.4)' }}>
      <div className="max-w-xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">Service Health Check</h1>
        
        {error && <div className="text-red-500 mb-4 font-semibold">Error: {error}</div>}

        <div className="bg-white rounded-lg shadow-lg mb-4">
          <table className="w-full">
            <thead>
              <tr className="border-b-2 border-gray-200">
                <th className="px-6 py-4 text-left text-lg font-bold">Service</th>
                <th className="px-6 py-4 text-left text-lg font-bold">Status</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr key={service.name} className="border-b border-gray-100">
                  <td className="px-6 py-4 text-gray-800 text-base font-semibold">{service.name}</td>
                  <td className="px-6 py-4">
                    <span className={`inline-block rounded-full px-4 py-1 text-base font-semibold
                      ${service.status === "Healthy" 
                        ? "bg-green-100 text-green-800" 
                        : "bg-red-100 text-red-800"}`}>
                      {service.status === "Healthy" 
                        ? "Healthy"
                        : `Unhealthy: ${service.errorCode}`}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={handleFetchHealth}
            disabled={loading}
            className="px-5 py-2 bg-blue-500 text-white rounded-md text-base font-semibold
                     hover:bg-blue-600 transition-colors disabled:opacity-50"
          >
            {loading ? "Loading..." : "Refresh Status"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HealthCheck;