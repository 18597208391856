import Tile from '../common/Tile';
import { shortenSourceName } from '../../utils/helpers';
import { useState, useRef, useEffect, useCallback } from 'react';
import { getAuthToken } from '../../services/api';

const ControlPanel = ({
  strategy,
  asset,
  handleToggleSide,
  handleChangeActivePos,
  gridLocation,
  timeframe
}) => {
  const ws = useRef();

  const [sources, setSources] = useState([]);
  const [gyroxArray, setGyroxArray] = useState([]);
  const [isActiveMenuOpen, setIsActiveMenuOpen] = useState(false);
  const [wsConnected, setwsConnected] = useState(false);

  useEffect(
    () => {
      if (!wsConnected) {
        connectWebsocket();
      }
      return () => { ws.current.close(); }
      }, []
    );

    useEffect(
      () => {
        if (wsConnected) {
          ws.current.close();
          setwsConnected(false);
          connectWebsocket();
        }
      }, [timeframe]
    )

  const connectWebsocket = () => {
    ws.current = new WebSocket(`${process.env.REACT_APP_API_WS_BASE_URL}/fetch-sources-socket`);
    const payload = {};
    payload.symbol = asset.symbol;
    payload.sectype = asset.sectype;
    payload.broker = asset.broker;
    payload.account = asset.account;
    payload.strategy = strategy;
    payload.timeframe = timeframe;
    payload.Authentication = getAuthToken();
    ws.current.onopen = () => {ws.current.send(JSON.stringify(payload)); setwsConnected(true); console.log(`connection estabilished for ${asset.symbol}`);};
    ws.current.onclose = () => {console.log(`connection released for ${asset.symbol}`)}
    gettingSourcesData();
  };

  const gettingSourcesData = useCallback(() => {
    if (!ws.current) return;

    ws.current.onmessage = body => {
      const data = body.data;
      if (data !== 'ping') {
        var message = JSON.parse(body.data);
        setSources(message.filter(item => item.strategy === 'gyrox-v1').sort((a, b) => a.sensitivity - b.sensitivity)); 
  
        const temp_families = Array.from(new Set(structuredClone(message).sort((a, b) => a.sensitivity - b.sensitivity).map((entry) => entry.source_name.length === 2 ? entry.source_name[0] : 'x')))
        // const temp_families = Array.from(new Set(message.sort((a, b) => a.sensitivity - b.sensitivity).map((entry) => entry.source_name.length === 2 ? entry.source_name[0] : 'x')))
        const temp_gyrox_array = [];
        message = message.filter(item => item.strategy === 'gyrox-v2').sort((a, b) => a.sensitivity - b.sensitivity);
        for (let i = 0; message.length > 0; i++) {
          const prefix = temp_families[i];
          const items = message.filter(item => item.source_name.startsWith(prefix));
          temp_gyrox_array.push(items);
          message = message.filter(item => !item.source_name.startsWith(prefix));
        }
        setGyroxArray(temp_gyrox_array);
      }
    };
  }, [])
  

  useEffect(() => {
    return () => {
      ws.current.close();
    };
  }, [])
  
  
  if (gridLocation === undefined) {
    gridLocation = "sm:col-start-2 sm:col-end-2 sm:row-start-1 col-start-1 col-end-1 row-start-1 row-end-1";
  }
  return (
    <div className={`${gridLocation}`}>
      <div className=" bg-gray-300 bg-opacity-50 shadow-md border-gray-300 border-opacity-20 border-4 px-2 pb-2 mb-4 rounded-3xl mr-4">
          <div className='m-auto'>
            <p className='font-bold font-sans pt-4 pb-2 tracking-widest align-middle text-sm lg:text-md md:text-md sm:text-sm text-gray-800 text-center'>
              {asset.broker === 'okx' ? asset.symbol.substring(
                0,
                asset.symbol.indexOf('/')
              ) : asset.symbol} - {timeframe}
            </p>
            <div className={`${strategy === "gyrox-v1" || timeframe === 'ZERO' ? "hidden" : "align-middle grid grid-cols-4 pb-2 text-center font-mono tracking-wide text-l"}`}>
              <div className='col-start-2 col-end-2 rounded-tl-2xl border-l-2 border-t-2 border-l-gray-300 border-t-gray-300'>
                  1
              </div>
              <div className='col-start-3 col-end-3 border-t-2 border-t-gray-300'>
                  2
              </div>
              <div className='col-start-4 col-end-4 rounded-tr-2xl border-t-2 border-r-2 border-t-gray-300 border-r-gray-300'>
                  3
              </div>
            </div>
          </div>
        {strategy === 'gyrox-v1' && timeframe !== 'ZERO' &&
        sources
        .map(source => (
          <div key={source.id} className="flex mb-1 gap-1">
            <Tile 
              onClick={() => handleChangeActivePos(source.source_name, asset, timeframe)}
              className={`w-1/3 ${source.isActive ? "bg-green-500" : "bg-orange-500"} border-opacity-0 shadow-lg
              ${source.source_name === "cross" ? "rounded-tl-3xl" :
                source.source_name === "green" ? "rounded-bl-3xl" : ""
              } text-center py-2 cursor-pointer`}
            />
            <Tile className="w-1/3 bg-white text-black text-center py-2 uppercase">
              {shortenSourceName(source.source_name)}
            </Tile>
            <Tile 
              onClick={() => handleToggleSide(source.source_name, asset, timeframe)}
              className={`w-1/3 text-center border-opacity-0 shadow-lg py-2 cursor-pointer
                ${(() => {
                  try {
                    return source.side.toUpperCase() === 'BUY' ? 'bg-green-500' : 'bg-red-500';
                  } catch (e) {
                    return "bg-black";
                  }
                })()} 
                ${source.source_name === "cross" ? "rounded-tr-3xl" :
                  source.source_name === "green" ? "rounded-br-3xl" : ""
                }
              `}
            />   
          </div>
        ))}
        
        {/* {strategy === 'gyrox-v2' && 
          <div className='flex mb-1 gap-1'>
            <div><div/>
          <div/>
        } */}
        {strategy === 'gyrox-v2' && timeframe !== 'ZERO' &&
        gyroxArray
        .map(family => (
          <div className="flex mb-1 gap-1">
            <Tile className={`w-1/4 bg-white text-black text-center py-2 uppercase ${family[0].source_name[0].toUpperCase() === "X" ? "rounded-tl-3xl" :
                                                                                         family[0].source_name[0].toUpperCase() === "G" ? "rounded-bl-3xl" : ""}`}>
              {family[0].source_name[0].toUpperCase()}
            </Tile>
            {family.map(sub_source => (
              <Tile 
                onContextMenu={(event) => { event.preventDefault(); handleChangeActivePos(sub_source.source_name, asset, timeframe)}}
                onClick={() => handleToggleSide(sub_source.source_name, asset, timeframe)}
                className={`w-1/4 text-center border-opacity-0 shadow-lg py-2 cursor-pointer
                  ${(() => {
                    try {
                      let style = ''
                      if (sub_source.side.toUpperCase() === 'BUY') {
                        if (sub_source.isActive) {
                          style += 'bg-green-600';
                        } else {
                          style += 'bg-green-500 bg-opacity-65';
                        }
                      } else {
                        if (sub_source.isActive) {
                          style += 'bg-red-500';
                        } else {
                          style += 'bg-red-500 bg-opacity-65';
                        }
                      }
                      // if (sub_source.isActive) {
                      //   style += ' outline outline-orange-400 border-opacity-100 border-1 border-orange-400 outline-3 outline-offset-0';
                      // }
                      return style;
                    } catch (e) {
                      return "bg-black";
                    }
                  })()} 
                  ${sub_source.source_name === "x3" ? "rounded-tr-3xl" :
                    sub_source.source_name === "g3" ? "rounded-br-3xl" : ""
                  }
                `}
              />)   
            )}
          </div>
        ))}
        {strategy === 'gyrox-v2' && window.screen.width <= 450 && (
            <div class="relative inline-block text-left mt-2">
              <div onClick={() => setIsActiveMenuOpen(!isActiveMenuOpen)}>
                <button type="button" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Set active
                  <svg className="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                    <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            {isActiveMenuOpen && (
              <div className="absolute left-0 z-10 mt-1 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                {gyroxArray.map(family => (
                    <div className="py-1">
                      {family.map(sub_source => (
                        <div className="block px-4 py-2 text-sm text-gray-700" onClick={() => { setIsActiveMenuOpen(!isActiveMenuOpen); handleChangeActivePos(sub_source.source_name, asset, timeframe) }}>{sub_source.source_name}</div>
                      ))}
                    </div>
                  ))
                }
              </div>
            )}
            </div>
          )}
      </div>
    </div>
  );
};

export default ControlPanel;