import React, { useState, useEffect } from 'react';

const OptionsForm = ({ asset, timeframeOptions, handleUpdateConfig, timeframesInfo }) => {
  const [basePosition, setBasePosition] = useState(timeframesInfo.base_quantity);
  const [step, setStep] = useState(timeframesInfo.step_quantity);
  const [timeframe, setTimeframe] = useState(timeframesInfo.active_timeframe);

  const [isSaved, setIsSaved] = useState(true);

  console.log(asset);

  useEffect(() => {
    setTimeframe(timeframesInfo.active_timeframe)
    setStep(timeframesInfo.step_quantity)
    setBasePosition(timeframesInfo.base_quantity)
  }, [timeframesInfo])

  useEffect(() => {
    if (
      basePosition !== timeframesInfo.base_quantity ||
      step !== timeframesInfo.step_quantity ||
      timeframe !== timeframesInfo.active_timeframe
    ) {
      setIsSaved(false);
    } else {
      setIsSaved(true);
    }
  }, [basePosition, step, timeframe]);

  const handleSave = (e) => {
    e.preventDefault();

    if (
      basePosition === timeframesInfo.base_quantity &&
      step === timeframesInfo.step_quantity &&
      timeframe === timeframesInfo.active_timeframe
    )
    {}
    else {
      handleUpdateConfig(
        asset.symbol,
        asset.broker,
        basePosition,
        step,
        timeframe,
        asset.account,
        asset.sectype
      );
      setIsSaved(true);
    }
    
  };
  
  return (
    <div className="max-w-sm h-[21rem] mx-auto bg-white shadow-[0_10px_30px_rgba(0,0,0,0.15)] rounded-lg p-6">
      <form>
        <div className="mb-4">
          <label htmlFor="basePosition" className="block text-sm font-medium text-gray-700">
            Base Position
          </label>
          <input
            type="number"
            id="basePosition"
            value={basePosition}
            onChange={(e) => setBasePosition(Number(e.target.value))}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="step" className="block text-sm font-medium text-gray-700">
            Step
          </label>
          <input
            type="number"
            id="step"
            value={step}
            onChange={(e) => setStep(Number(e.target.value))}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="timeframe" className="block text-sm font-medium text-gray-700">
            Timeframe
          </label>
          <select
            id="timeframe"
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            {timeframeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <button
        type="submit"
        className="w-full px-4 py-2 bg-blue-500 text-white rounded-md text-sm font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={handleSave}
      >
        Confirm
      </button>
      {!isSaved && <p className="text-[#F0122D] font-bold grid place-items-center h-8">Confirm changes</p>}
      {isSaved && <p className="text-[#009b76] font-bold grid place-items-center h-8">Config is up to date</p>}
      </form>
    </div>
  );
};

export default OptionsForm;