import React, { useRef, useState } from 'react';
import TornadoChart from '../components/Tornado/TornadoChart';

function TornadoPage() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    try {
      const response = await fetch('/data-vis/upload-tornado', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      console.log(result);
      console.log(result.metadata);

      const totalData = result.totalData.map(item => ({
        Strike: item.Strike,
        Calls: item.Type === 'Call' ? Math.abs(item.DollarVolume) * -1 : 0,
        Puts: item.Type === 'Put' ? Math.abs(item.DollarVolume) : 0,
      }));

      // const totalData = result.totalData.reduce((acc, item) => {
      //   // Find an existing entry in the accumulator with the same Strike
      //   let existing = acc.find(data => data.Strike === item.Strike);
      
      //   if (existing) {
      //     // Update the existing entry based on Type
      //     if (item.Type === 'Call') {
      //       existing.Calls += Math.abs(item.DollarVolume) * -1;
      //     } else if (item.Type === 'Put') {
      //       existing.Puts += Math.abs(item.DollarVolume);
      //     }
      //   } else {
      //     // Add a new entry if none exists
      //     acc.push({
      //       Strike: item.Strike,
      //       Calls: item.Type === 'Call' ? Math.abs(item.DollarVolume) * -1 : 0,
      //       Puts: item.Type === 'Put' ? Math.abs(item.DollarVolume) : 0,
      //     });
      //   }
      
      //   return acc;
      // }, []);

      
      

      const todayData = result.todayData.map(item => ({
        Strike: item.Strike,
        Calls: item.Type === 'Call' ? Math.abs(item.VolumeToday) * -1 : 0,
        Puts: item.Type === 'Put' ? Math.abs(item.VolumeToday) : 0,
      }));

      // const todayData = result.todayData.reduce((acc, item) => {
      //   // Find an existing entry in the accumulator with the same Strike
      //   let existing = acc.find(data => data.Strike === item.Strike);
      
      //   if (existing) {
      //     // Update the existing entry based on Type
      //     if (item.Type === 'Call') {
      //       existing.Calls += Math.abs(item.VolumeToday) * -1;
      //     } else if (item.Type === 'Put') {
      //       existing.Puts += Math.abs(item.VolumeToday);
      //     }
      //   } else {
      //     // Add a new entry if none exists
      //     acc.push({
      //       Strike: item.Strike,
      //       Calls: item.Type === 'Call' ? Math.abs(item.VolumeToday) * -1 : 0,
      //       Puts: item.Type === 'Put' ? Math.abs(item.VolumeToday) : 0,
      //     });
      //   }
      
      //   return acc;
      // }, []);

      console.log(todayData);
      console.log(totalData);


      setData({
        totalData,
        todayData,
        totalDollarVolumePuts: result.metadata.totalDollarVolumePuts,
        totalDollarVolumeCalls: result.metadata.totalDollarVolumeCalls,
        todayVolumePuts: result.metadata.todayVolumePuts,
        todayVolumeCalls: result.metadata.todayVolumeCalls,
        totalCallPutRatio: result.metadata.totalDollarVolumeCalls / result.metadata.totalDollarVolumePuts,
        todayCallPutRatio: result.metadata.todayVolumeCalls / result.metadata.todayVolumePuts,
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
      e.target.value = '';
    }
  };

  const triggerFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Tornado Graphs</h1>
      <button
        onClick={triggerFileUpload}
        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring"
      >
        Choose .csv file
      </button>
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileUpload}
        className="hidden"
      />
      {loading && <p>Loading...</p>}
      {data && (
        <TornadoChart
          totalData={data.totalData}
          todayData={data.todayData}
          totalCallPutRatio={data.totalCallPutRatio}
          todayCallPutRatio={data.todayCallPutRatio}
          totalDollarVolumePuts={data.totalDollarVolumePuts}
          totalDollarVolumeCalls={data.totalDollarVolumeCalls}
          todayVolumePuts={data.todayVolumePuts}
          todayVolumeCalls={data.todayVolumeCalls}
        />
      )}
    </div>
  );
}

export default TornadoPage;