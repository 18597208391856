// Format currency
export const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
  };
  
  // Format date
export const formatDate = (date) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};
  
// Truncate text
export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};


export const sortObjectsByDateDesc = (objArray, ) => {
  return objArray.sort((a, b) => new Date(b["timestamp"]) - new Date(a["timestamp"]));
};


export const reversePatchSec = (symbol, sectype) => {
  const reversePatches = new Map([
    ["AUD", new Map([["symbol", "6A"]])],
    ["JPY", new Map([["symbol", "6J"]])],
    ["CAD", new Map([["symbol", "6C"]])],
    ["EUR", new Map([["symbol", "6E"]])],
    ["CHF", new Map([["symbol", "6S"]])],
    ["GBP", new Map([["symbol", "6B"]])],
    ["BRE", new Map([["symbol", "6L"]])],
    ["ZAR", new Map([["symbol", "6Z"]])],
    ["DX", new Map([["exchange", "ICEUS"]])],
    ["CT", new Map([["exchange", "ICEUS"]])],
    ["CC", new Map([["exchange", "ICEUS"]])],
    ["KC", new Map([["exchange", "ICEUS"]])],
    ["MNQ", new Map([["exchange", "CME_MINI"]])]
  ]);
  const patch = reversePatches.get(symbol);
  let patchSymbol = undefined;
  let patchExchange = undefined;
  if (patch) {
    patchSymbol = reversePatches.get(symbol).get("symbol");
    patchExchange = reversePatches.get(symbol).get("exchange");
  }
  return [
    (patchSymbol === undefined) ?
    (symbol + (sectype === "CONTFUT" ? "1!" : "")) : 
    (patchSymbol + (sectype === "CONTFUT" ? "1!" : "")),
    patchExchange
  ];
};


export const shortenSourceName = (sourceName) => {
  const patches = new Map([
    ["cross", "X"],
    ["dot", "O"],
    ["red", "R"],
    ["yellow", "Y"],
    ["green", "G"]
  ]);
  return patches.get(sourceName);
};

export const reversePatchCrypto = (symbol) => {
  return symbol.replace("/USDT:USDT", "USDT.P");
}

// export const timeframes = [
//   '1s', '5s', '10s', '15s', '30s',
//   '1m', '2m', '4m', '8m', '16m', '32m', '45m', '64m', '90m',
//   '2h', '3h', '6h', '12h',
//   '1d', '2d', '4d', '8d'
// ];

export const timeframes = [
  'ZERO',
  '5s', '10s', '15s', '30s',
  '1m', '2m', '4m', '8m', '16m', '32m', '45m', '64m', '90m',
  '3h', '6h', '12h'
]

export const services = [
  { name: "Tornado", url: "/health/healthcheck-data-vis" },
  { name: "OKX Strategy", url: "/health/healthcheck-okx-strategy" },
  { name: "IB Strategy", url: "/health/healthcheck-ib-strategy" },
  { name: "IB Trader", url: "/health/healthcheck-ib-client" },
  { name: "OKX Trader", url: "/health/healthcheck-okx-client" },
];