import axios from 'axios';
import { services } from '../utils/helpers';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000
});

//// EXPERIMENTAL SECTION ////
//// EXPERIMENTAL SECTION ////
//// EXPERIMENTAL SECTION ////

export const fetchAccountsExp = async () => {
  const response = await axios.get('/user/exp/accounts');
  return response.data;
}

export const fetchStrategiesExp = async () => {
  const response = await axios.get('/user/exp/strategies');
  return response.data;
}

//// EXPERIMENTAL SECTION ////
//// EXPERIMENTAL SECTION ////
//// EXPERIMENTAL SECTION ////


export const getAuthToken = () => {
  if (api.defaults.headers.common['Authorization'] !== undefined) {
    return api.defaults.headers.common['Authorization'];
  }
}



export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

export const fetchAccounts = async () => {
  const response = await api.get('/user/accounts');
  return response.data;
};

export const fetchAssets = async (account, broker) => {
    const response = await api.get(
      `/user/fetch-assets`,
      {
        params: {
          account,
          broker
        }
      }
    );
  return response.data;
};

export const fetchUserProfile = async () => {
  const response = await api.get('/user/profile');
  return response.data;
};

export const fetchSupportedBrokers = async () => {
  const response = await api.get('/brokers/supported');
  return response.data;
};

export const linkBroker = async (broker, account, password, apiKey = null) => {
  const response = await api.post(`/brokers/link`, {
                                                      broker: broker,
                                                      account: account,
                                                      password: password,
                                                      api_key: apiKey
                                                    });
  if (response.status !== 200) {
    return false;
  }
  return true;
};


export const unlinkBroker = async (_id) => {
  const response = await api.delete(
    `/brokers/unlink`,
    {
      params: {
        _id
      }                                                  
    });
  if (response.status !== 200) {
    return false;
  }
  return true;
};


export const fetchMasterStatus = async (broker) => {
  const response = await api.get(
    `/brokers/master-status`,
    {
      params: {
        broker
      }
    }
  );
  return response.data;
};


export const fetchStatistics = async (broker, account, symbol, sectype) => {
  const response = await api.get(
    '/user/fetch-statistics',
    {
      params: {
        broker, account, symbol, sectype
      }
    }
  );
  return response.data;
}


export const changeActiveSource = async (sourceName, symbol, dest, timeframe, strategy, account = null, sectype = null) => {
  try {
    let url, data;
    switch (dest) {
      case 'okx':
        url = '/user/change-active-source-crypto';
        data = { symbol: symbol, broker: dest, name: sourceName, account: account, timeframe: timeframe, strategy: strategy };
        break;
      case 'ib':
      case 'ig':
        url = '/user/change-active-source-sec';
        data = { symbol: symbol, broker: dest, sectype, name: sourceName, account: account, timeframe: timeframe, strategy: strategy };
        break;
      default:
        throw new Error(`Unsupported broker: ${dest}`);
    }

    const response = await api.put(url, data);
    return response.data.now_active;
  } catch (error) {
    console.error(`Exception: Cannot connect to DBCLIENT backend: ${error}`);
    if (error.response && error.response.data) {
      console.error('Server response:', error.response.data);
    }
    return null;
  }
};


export const toggleSide = async (sourceName, symbol, dest, timeframe, account = null, sectype = null) => {
  try {
    let url, data;
    switch (dest) {
      case 'ib':
        url = '/user/toggle-side-sec';
        data = {name: sourceName, symbol: symbol, broker: dest, sectype: sectype, account: account, timeframe: timeframe };
        break;
      case 'ig':
        url = '/user/toggle-side-sec';
        data = { epic: symbol, broker: dest, sectype: sectype, account: account };
        break;
      case 'okx':
        url = '/user/toggle-side-crypto';
        data = { name: sourceName, symbol: symbol, broker: dest, account: account, timeframe: timeframe};
        break;
      default:
        throw new Error(`Unsupported broker: ${dest}`);
    }
    const response = await api.put(url, data, { timeout: 10000 });
    return response.data.new_status;
  } catch (error) {
    console.error(`Exception: Cannot connect to DBCLIENT: ${error}`);
    return null;
  }
};


export const toggleSpinDown = async (symbol, dest, account = null, sectype = null) => {
  try {
    const url = '/user/toggle-spin-down';
    const data = {
      symbol: symbol,
      broker: dest,
      sectype: sectype,
      account: account
    };

    const response = await api.put(url, data, { timeout: 10000 });
    return response.data.new_status;
  } catch (error) {
    console.error(`Exception: Cannot connect to DBCLIENT: ${error}`);
    return null;
  }
};


export const updateAssetConfig = async (symbol, dest, basePosition, step, timeframe, strategy, account = null, sectype = null) => {
  try {
    let data;
    data = {
      base_position:    basePosition,
      step:             step,
      active_timeframe: timeframe,
      strategy:         strategy
    };
    data.asset = {
      symbol:  symbol,
      broker:  dest,
      account: account,
      sectype: sectype
    };
    
    const response = await api.put('/user/update-asset-config', data, { timeout: 10000 });
    return response.data.new_config;
  } catch (error) {
    console.error(`Exception: Cannot connect to DBCLIENT: ${error}`);
    return null;
  }
};

export const toggleMaster = async (symbol, dest, account = null, sectype = '') => {
  try {
    let url, data;
    switch (dest) {
      case 'okx':
        url = '/user/toggle-crypto-algo';
        data = { symbol, broker: dest };
        break;
      case 'ib':
        url = '/user/toggle-sec-algo';
        data = { symbol, broker: dest, sectype, account };
        break;
      case 'ig':
        url = '/user/toggle-sec-algo';
        data = { epic: symbol, broker: dest };
        break;
      default:
        throw new Error(`Unsupported broker: ${dest}`);
    }

    const response = await api.put(url, data);
    return response.data.new_status;
  } catch (error) {
    console.error(`Exception: Cannot connect to DB_CLIENT: ${error}`);
    return null;
  }
};

export const toggleFestival = async (symbol, dest, account = null, sectype = '') => {
  try {
    let url, data 

    switch (dest) {
      case 'okx':
        url = '/user/toggle-festival-crypto';
        data = {
          symbol: symbol,
          broker: dest,
        };
        break;
      case 'ib':
        url = '/user/toggle-festival-sec';
        data = {
          symbol: symbol,
          broker: dest,
          sectype: sectype,
          account: account,
        };
        break;
      case 'ig':
        url = '/user/toggle-festival-sec';
        data = {
          epic: symbol,
          broker: dest,
        };
        break;
      default:
        console.error(`Unsupported broker: ${dest}`);
        return null;
    }

    const response = await api.put(url, data);
    return response.data.new_status;
  } catch (error) {
    console.error(`Error toggling festival for symbol: ${symbol}, broker: ${dest}`, error);
    return null; 
  }
};


export const toggleOffensive = async (symbol, dest, account = null, sectype = '') => {
  try {
    let url, data 

    switch (dest) {
      case 'okx':
        url = '/user/toggle-offensive-crypto';
        data = {
          symbol: symbol,
          broker: dest,
        };
        break;
      case 'ib':
        url = '/user/toggle-offensive-sec';
        data = {
          symbol: symbol,
          broker: dest,
          sectype: sectype,
          account: account,
        };
        break;
      case 'ig':
        url = '/user/toggle-offensive-sec';
        data = {
          epic: symbol,
          broker: dest,
        };
        break;
      default:
        console.error(`Unsupported broker: ${dest}`);
        return null;
    }

    const response = await api.put(url, data);
    return response.data.new_status;
  } catch (error) {
    console.error(`Error toggling offensive for symbol: ${symbol}, broker: ${dest}`, error);
    return null; 
  }
};


export const switchMaster = async (dest) => {
  let url, data

  try {
    switch (dest) {
      case 'okx':
        url = '/user/toggle-broker';
        data = { name: dest };
        break;
      case 'ib':
        url = '/user/toggle-broker';
        data = { name: dest };
        break;
      default:
        console.error(`Unsupported broker: ${dest}`);
        return null;
    }

    const response = await api.put(url, data, { timeout: 10000 });
    return response.data.new_status; 
  } catch (error) {
    console.error(`Error switching master for broker: ${dest}`, error);
    return null; 
  }
};


export const toggleActive = async (symbol, broker, account = 'default', sectype = '') => {
  try {
    const data = { broker };
    if (broker === 'ig') {
      data.epic = symbol;
    } else {
      data.symbol = symbol;
    }
    data.account = account;
    if (sectype) data.sectype = sectype;
    const response = await api.put('/user/toggle-active', data);
    return response.data.new_status;
  } catch (error) {
    console.error('Error toggling active status:', error);
    return null;
  }
};

export const fetchDeepAssets = async (broker, account) => {
  try {
    const params = { broker , account};
    const response = await api.get('/user/deep-fetch-assets', {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching assets:", error);
    throw error; 
  }
};

export const sendHealthChecks = async () => {
  const statusResults = [];

  try {
    for (const service of services) {
      try {
        const response = await api.get(service.url, { timeout: 1000 });
        if (response.status === 200) {
          statusResults.push({
            name: service.name,
            status: "Healthy",
            errorCode: null
          });
        } else {
          statusResults.push({
            name: service.name,
            status: "Unhealthy",
            errorCode: `Status code ${response.status}`,
          });
        }
      } catch (err) {
        statusResults.push({
          name: service.name,
          status: "Unhealthy",
          errorCode: err.message
        });
      }
    }
    return {success: true, message: "Success", statuses: statusResults};
  } catch (err) {
    return {success: false, message: `Failed to load: ${err.message}`, statuses: statusResults};
  }
}

export const setTimeframeSide = async (side, symbol, dest, strategy, timeframe, account = null, sectype = null) => {
  try {
    const data = {
      side:      side,
      symbol:    symbol,
      broker:    dest,
      strategy:  strategy,
      timeframe: timeframe,
      account:   account,
      sectype:   sectype
    };
    
    const response = await api.put('/user/set-timeframe-side', data, { timeout: 10000 });
    return response.data;
  } catch (error) {
    console.error(`Exception: Cannot connect to DBCLIENT: ${error}`);
    return null;
  }
};

export const setJ2F = async (isJ2F, symbol, dest, timeframe, account = null, sectype = null) => {
  try {
    const data = {
      symbol:    symbol,
      broker:    dest,
      timeframe: timeframe,
      account:   account,
      sectype:   sectype,
      isJ2F:     isJ2F
    };
    
    const response = await api.put('/user/set-j2f-02f', data, { timeout: 10000 });
    return response.data;
  } catch (error) {
    console.error(`Exception: Cannot connect to DBCLIENT: ${error}`);
    return null;
  }
};


export default api;