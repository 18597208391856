import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function HomePage() {
  const history = useHistory();
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      history.push('/dashboard');
    }
  }, [user, history]);

  return (
    <div className="text-center">
      <h1 className="text-4xl font-bold mb-4 text-gray-800 bodoni-moda">MOZART TRADER</h1>
      <p className="text-xl mb-8 text-gray-600">Manage your trading strategies across multiple brokers with ease.</p>
      <div className="space-x-4">
        <Link to="/login" className="bg-accent text-white px-6 py-2 rounded-md hover:bg-accent-dark transition duration-300">Login</Link>
        <Link to="/register" className="bg-white text-accent px-6 py-2 rounded-md border-2 border-accent hover:bg-accent hover:text-white transition duration-300">Register</Link>
      </div>
    </div>
  );
}

export default HomePage;